import React from 'react'
import { MdDashboard, MdOutlineBusinessCenter, MdSportsScore } from "react-icons/md";
import { HiOutlineUsers } from "react-icons/hi";
import { GiInspiration, GiPodiumWinner } from "react-icons/gi";
import { GrCurrency, GrWorkshop } from "react-icons/gr";
import { FcBusinessman } from "react-icons/fc";
import { BsQuestionSquare } from 'react-icons/bs';
import { BiCategoryAlt } from 'react-icons/bi';

const Subscriber = React.lazy(() => import("../pages/subscriber/Subscriber"));
const Dashboard = React.lazy(() => import("../pages/dashboard/Dashboard"));
const BusinessCategory = React.lazy(() => import("../pages/businessCategory/BusinessCategory"));
const Business = React.lazy(() => import("../pages/business/Business"));
const Entrepreneur = React.lazy(() => import("../pages/entrepreneur/Entrepreneur"));
const Inspiration = React.lazy(() => import("../pages/inspiration/Inspiration"));
const NavigationResource = React.lazy(() => import("../pages/navigationResource/NavigationResource"));
const Currency = React.lazy(() => import("../pages/currency/Currency"));
const Question = React.lazy(() => import("../pages/questions/Question"));
const Scoreboard = React.lazy(() => import("../pages/scoreboard/Scoreboard"));
const Winner = React.lazy(() => import("../pages/winner/Winner"));
const BusinessSubCategory = React.lazy(() => import("../pages/businessSubCategory/BusinessSubCategory"));

export const adminRoutes = [
    {
        path: "/dashboard/data",
        element: <Dashboard />,
        name: "Dashboard",
        icon: <MdDashboard size={"1.5em"} />,
        sideBarVisible: true,
    },
    {
        path: "/dashboard/busCategory",
        element: <BusinessCategory />,
        name: "Business Category",
        icon: <BiCategoryAlt size={"1.5em"} />,
        sideBarVisible: true,
    },
    {
        path: "/dashboard/busSubCat",
        element: <BusinessSubCategory />,
        name: "Business Sub Category",
        icon: <BiCategoryAlt size={"1.5em"} />,
        sideBarVisible: true,
    },
    {
        path: "/dashboard/business",
        element: <Business />,
        name: "Business",
        icon: <MdOutlineBusinessCenter size={"1.5em"} />,
        sideBarVisible: true,
    },
    {
        path: "/dashboard/inspiration",
        element: <Inspiration />,
        name: "Inspiration",
        icon: <GiInspiration size={"1.5em"} />,
        sideBarVisible: true,
    },
    {
        path: "/dashboard/entrepreneur",
        element: <Entrepreneur />,
        name: "Entrepreneur",
        icon: <FcBusinessman size={"1.5em"} />,
        sideBarVisible: true,
    },
    {
        path: "/dashboard/navigationResource",
        element: <NavigationResource />,
        name: "Navigation Resource",
        icon: <GrWorkshop size={"1.5em"} />,
        sideBarVisible: true,
    },
    {
        path: "/dashboard/currency",
        element: <Currency />,
        name: "Currency",
        icon: <GrCurrency size={"1.5em"} />,
        sideBarVisible: true
    },
    {
        path: "/dashboard/questions/:page",
        element: <Question />,
        name: "Questions",
        icon: <BsQuestionSquare size={"1.5em"} />,
        sideBarVisible: true
    },
    {
        path: "/dashboard/scoreboard",
        element: <Scoreboard />,
        name: "Scoreboard",
        icon: <MdSportsScore size={"1.5em"} />,
        sideBarVisible: true
    },
    {
        path: "/dashboard/winner",
        element: <Winner />,
        name: "Winners",
        icon: <GiPodiumWinner size={"1.5em"} />,
        sideBarVisible: true
    },
    // {
    //     path: "/subscribers",
    //     element: <Subscriber />,
    //     name: "Subscribers",
    //     icon: <HiOutlineUsers size={"1.5em"} />,
    //     sideBarVisible: true,
    // }
];
